@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700;900&display=swap');

html,
body {
  margin: 0;
  height: 100%;
}
html {
  overflow-y: scroll;
}

#root {
  height: 100%;
}

::selection {
  background: rgba(59, 168, 235, 0.7);
  color: #fff;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(59, 168, 235, 0.9);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(59, 168, 235, 1);
}
